<template>
	<div>
		<div class="container main-holder">
			<div class="row navbar align-items-center">
				<div class="col-sm-12 col-md-12 col-lg-6 align-self-center">
					<h3 class="brand-name m-b-0">
						{{ companyInfo.brandTitle }}
						<span class="hamburger" @click="hamburger = !hamburger"><i class="fa" :class="hamburger ? 'fa-times' : 'fa-bars'"></i></span>
					</h3>
				</div>
				<div class="col-sm-12 col-md-12 col-lg-6 align-self-end">
					<ul class="list-group list-group-horizontal nav-list justify-content-end" :class="hamburger ? 'show' : ''">
						<!-- <li class="list-group-item active"><a href='#nav' v-smooth-scroll>Anasayfa</a></li> -->
						<li class="list-group-item"><a href="#urunler" v-smooth-scroll>Ürünler</a></li>
						<li class="list-group-item"><a href="#hakkimizda" v-smooth-scroll>Hakkımızda</a></li>
						<li class="list-group-item"><a href="#iletisim" v-smooth-scroll>İletişim</a></li>
					</ul>
				</div>
			</div>

			<div class="jumbotron">
				<div class="flex-50">
					<h1 class="fs-1 ">{{ companyInfo.header }}</h1>
					<a href="#urunler" class="btn btn-glow m-t-40" v-smooth-scroll>Keşfet</a>
				</div>
				<div class="flex-50 img-wrapper">
					<img class="img-responsive holder-image align-self-end" :src="'/img/' + companyInfo.jumbotronImage" alt="" />
				</div>
			</div>

			<div id="urunler" class="row products-holder m-b-20 m-padding about-list">
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" v-for="(item, index) in aboutCompanyList" :key="index">
					<div class="card text-center  m-l-r-0">
						<div class="card-body p-l-r-0">
							<a v-if="item.link" :href="item.link" style="color: inherit;" target="_blank">
								<h3 class="m-b-20 text-orange"><i :class="item.icon"></i></h3>
								<h5 class="card-title" style="margin-bottom: 0;" v-html="item.title"></h5>
								<p class="card-text fs-6 text-grey">{{ item.desc }}</p>
							</a>
							<h3 v-if="!item.link" class="m-b-20 text-orange"><i :class="item.icon"></i></h3>
							<h5 v-if="!item.link" class="card-title" style="margin-bottom: 0;" v-html="item.title"></h5>
							<p v-if="!item.link" class="card-text fs-6 text-grey">{{ item.desc }}</p>
						</div>
					</div>
				</div>
			</div>

			<div class="row align-items-center m-b-40 m-padding">
				<div class="col-12 align-self-center" style="display: flex;justify-content: space-between;">
					<h3 style="width: 50%;">Ürünler</h3>
					<!-- <span class="text-grey filter-btn">Sırala <i class="fa fa-filter"
                                style="font-size: 12px;"></i></span> -->
				</div>
			</div>

			<div class="row products-holder m-padding" style="margin-bottom: 10rem;">
				<div class="col-xs-3 col-sm-6 col-md-4 col-lg-4" v-for="(item, index) in products" :key="index">
					<div class="card" :class="index % 3 == 0 ? 'm-l-0' : index % 2 == 0 ? 'm-r-0' : ''">
						<!-- :style="(index % 4) == 0 && index != 0 ? 'margin-top:-30px;': ''" -->
						<!-- index == 0 ? 'margin-top:20px;': index == 2 ? 'margin-top:20px;': -->
						<img class="card-img-top" :src="'/img/' + item.img" alt="" />
						<div class="card-body">
							<h5 v-if="item.title" class="card-title">{{ item.title }}</h5>
							<p v-if="item.desc" class="card-text price">
								<small v-if="item.oldPrice">{{ item.oldPrice }}</small> {{ item.desc }}
							</p>
							<p v-if="item.colors" class="color-badges">
								<span v-for="colorItem in item.colors" :key="colorItem" class="badge color-badge" :class="'badge-' + colorItem"></span>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div id="iletisim" class="row align-items-center m-b-40 m-padding">
				<div class="col-12 align-self-center text-center" style="display: flex;justify-content: space-between;flex-direction: column; ">
					<h3>İletişim</h3>
					<div class="input-group m-t-30" style="align-content: center;">
						<input type="text" placeholder="İletmek istediğiniz mesajı buraya yazın" class="form-control contact-input" style="height: auto;" />
						<input type="button" value="Gönder" class="btn btn-glow" />
					</div>
				</div>
			</div>
		</div>
		<div id="hakkimizda" class="row align-items-center footer" style="margin-top:10rem;">
			<div class="container">
				<div class="row">
					<div class="col-12 align-self-center" style="display: flex;justify-content: space-between;">
						<div class="col align-self-center">
							<h3 class="brand-name m-b-0" style="color:white;">{{ companyInfo.brandTitle }}</h3>
							<h5 class="brand-name m-b-0" style="color:white;">{{ companyInfo.brandDesc }}</h5>
						</div>
						<div class="col align-self-end text-end" style="text-align:right;">
							<p class="m-b-0" style="color:white;">{{ companyInfo.line2 }}</p>
							<p class="m-b-0" style="color:white;">{{ companyInfo.line1 }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { db } from '@/store/db'
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				hamburger: false,
				products: [],
				companyInfo: [],
				aboutCompanyList: [],
			}
		},
		firestore: {
			products: db.collection('products'),
			aboutCompanyList: db.collection('aboutCompanyList'),
			companyInfo: db.collection('companyInfos').doc('address'),
		},
	}
</script>
