import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

// https://www.npmjs.com/package/vue2-smooth-scroll
import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

Vue.config.productionTip = false

/* new Vue({
  render: function (h) { return h(App) }
}).$mount('#app') */
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
