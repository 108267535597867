<template>
	<div id="app">

		<home v-if="!showAdmin" />
		<about v-if="showAdmin" />
	</div>
</template>

<script>
	import { db } from '@/store/db'
    import Home from './views/Home.vue';
    import About from './views/About.vue';
    
	export default {
  components: { Home, About },
		data() {
			return {
                showAdmin: false
            }
		},
        created(){
            this.showAdmin = this.$route.fullPath == '/?admin=1'
        },
		firestore: {
			products: db.collection('products'),
			aboutCompanyList: db.collection('aboutCompanyList'),
			companyInfo: db.collection('companyInfos').doc('address'),
		},
	}
</script>

<style>
	@import url('assets/page.css');
	@import url('assets/fonts/style.css');

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}
</style>
