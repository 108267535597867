<template>
	<div class="container mt-5">
		<div class="row">
			<div class="col-lg-6 col-xs-12">
				<div class="col-12 d-flex justify-content-center" style="flex-direction:column;" action="">
					<div class="form-group">
						<label for="exampleInputEmail1">Başlık 1</label>
						<input class="form-control" type="text" v-model="companyInfo.brandTitle" style="max-width:500px;" />
					</div>

					<div class="form-group">
						<label for="exampleInputEmail1">Başlık 2</label>
						<input class="form-control" type="text" v-model="companyInfo.brandDesc" style="max-width:500px;" />
					</div>

					<div class="form-group">
						<label for="exampleInputEmail1">Başlık 3</label>
						<input class="form-control" type="text" v-model="companyInfo.header" style="max-width:500px;" />
					</div>

					<div class="form-group">
						<label for="exampleInputEmail1">Adres 1</label>
						<input class="form-control" type="text" v-model="companyInfo.line1" style="max-width:500px;" />
					</div>

					<div class="form-group">
						<label for="exampleInputEmail1">Adres 2</label>
						<input class="form-control" type="text" v-model="companyInfo.line2" style="max-width:500px;" />
					</div>

					<div class="form-group">
						<label for="exampleInputEmail1">Anasayfa Görsel</label>
						<input class="form-control" type="text" v-model="companyInfo.jumbotronImage" style="max-width:500px;" />
					</div>
					<div class="form-group">
						<div class="row">
							<div class="col-12">
								<label for="exampleInputEmail1">Yeni Ürün</label>
							</div>
							<div class="col-8">
								<input class="form-control" type="text" v-model="newName" style="max-width:500px;" />
							</div>
							<div class="col-4">
								<button class="btn btn-primary btn-block" @click="newProduct">Ekle</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-xs-12">
				<div class="card">
					<div class="card-header" style="border-radius:35px 35px 0px 0px;">
						<h3 class="pt-2 pl-2">Ürünler</h3>
					</div>
					<div class="card-body">
						<ul class="list-group">
							<a href="#" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" v-for="(item, index) in products" :key="index">
								<span class="text-dark">{{ item.img }}</span>
								<span class="badge badge-danger badge-pill" @click="removeItem(item.img)">x</span>
							</a>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="row mb-5 pb-5">
			<div class="col-12">
				<button class="btn btn-primary btn-block" @click="save">Kaydet</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { db } from '@/store/db'
	export default {
		data() {
			return {
				hamburger: false,
				products: [],
				companyInfo: [],
				aboutCompanyList: [],
				newName: '',
			}
		},
		firestore: {
			products: db.collection('products'),
			aboutCompanyList: db.collection('aboutCompanyList'),
			companyInfo: db.collection('companyInfos').doc('address'),
		},
		created() {
			
		},
		methods: {
			newProduct() {
				db.collection('products').doc(this.newName).set({ img: this.newName })
			},
			removeItem(name) {
				db.collection('products')
					.doc(name)
					.delete()
				/* this.products.splice(index, 1) */
			},
			save() {
				db.collection('companyInfos')
					.doc('address')
					.set(this.companyInfo)
					.then(() => {
						console.log('user updated!')
					})
			},
		},
	}
</script>

<style scoped>
	.card:hover {
		scale: 100% !important;
	}
</style>
